<template>
	<div>
		这是测试页面
	</div>
</template>

<script>
	
	export default {
		
		data() {
			return {
			

			};
		},
		mounted() {
			
		},
		methods: {
			}
	}
</script>


